.lpnp_entry {
    background: #383D54;
    position: absolute;
    left: 0.24rem;
    top: 0.24rem;
    padding: .4rem .34rem .2rem .3rem;
    border-radius: .16rem;
}

.lpnp_entry_content {
    width: 4.38rem;
    line-height: .46rem;
}

.lpnp_entry_txt {
    color: #fff;
    font-size: .29rem;
    letter-spacing: .02rem;
    font-weight: 400;
    margin-bottom: .4rem;
}

.lpnp_entry_btns{
    display: flex;
    justify-content: space-between;
}

.lpnp_entry_btn {
    font-size: 0.28rem;
    color: #fff;
    text-align: center;
    width: 0.28rem;
    height: 0.71rem;
    line-height: 0.71rem;
    flex: 1 1;
    border-radius: 0.08rem;
    cursor: pointer;
    position: relative;
}

.lpnp_entry_confirm {
    background: #2A69E2;
    font-weight: 500;
    margin-right: .21rem;
}

.lpnp_entry_cancel {
    background: #272B3E;
}

.lpnp_entry_confirm::before, .lpnp_entry_cancel::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    border: none;
    border-color: rgba(0,0,0,.4);
    border-radius: inherit;
    transform: translate(-50%, -50%);
    opacity: 0;
    content: '';
}

.lpnp_entry_confirm:active::before,.lpnp_entry_cancel:active::before {
    opacity: 0.6;
}
