.mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    // background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
}
.mask_hidden_ani {
    animation: maskHidden 0.4s forwards;
}

.modal {
    position: absolute;
    left: 50%;
    top: -50%;
    transform: translateX(-50%);
    width: 7.13rem;
    height: auto;
    border-radius: 0.2rem;
    display: block;
    z-index: 1001;
    padding: 0.34rem;
    opacity: 0;
    transform-origin: 0 0;
    transition: all 1s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dModal {
    background: #24283e;
    box-shadow: 0 0.02rem 0.32rem rgba(0, 0, 0, 0.8);
}

.srModal {
    background: #f0f4fa;
    box-shadow: 0 0.21rem 0.24rem 0px rgb(189 197 212);
}

.modalShow_ani {
    transform-origin: 0 0;
    animation: boxShow 0.3s forwards;
}
.modalHidden_ani {
    animation: boxHidden 0.3s forwards;
}

.modalCenter_ani {
    top: 50%;
    transform: translate(-50%, -50%);
    animation: boxShow_center 0.3s forwards;
}
.modalHidden_center_ani {
    animation: boxHidden_center 0.3s forwards;
}

.visible {
    display: block;
}

.title {
    height: 0.57rem;
    line-height: 0.57rem;
    font-size: 0.36rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0.12rem;
}

.dTitle {
    color: #fff;
}

.srTitle {
    color: rgba(60, 66, 72, 1);
}

.closeIcon {
    position: absolute;
    right: 0;
    top: 0;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    padding: 5px;
    text-align: center;
    color: #fff;
}

.body {
    font-size: 0.36rem;
    line-height: 0.57rem;
    text-align: center;
    letter-spacing: 0.04rem;
    padding-top: 0.3rem;
}

.dBody {
    color: #fff;
}

.srBody {
    color: #3c4248;
}

.footer {
    width: 100%;
    margin-top: 0.43rem;
    button {
        outline: none;
        border: none;
        float: right;
        cursor: pointer;
        color: #fff;
    }
}
.footer_btn {
    // border-top: 1px solid #fff;
    width: 100%;
    height: 100%;
    display: flex;
    .btn_ok {
        width: 3.93rem;
        height: 1.05rem;
        text-align: center;
        margin-right: 0.25rem;
        font-size: 0.36rem;
        font-weight: 400;
        border-radius: 0.16rem;
        position: relative;
        // background-image: linear-gradient(#fff, #fff), linear-gradient(#ffffff, #1CADFF);
        // background-clip: padding-box, border-box;
        // background-origin: border-box;
        // border: 0.04rem solid;
        // border-image: linear-gradient(180deg, #ffffff, #1CADFF);
    }

    .btn_ok_d {
        background: #2a69e2;
        color: #fff;
    }

    .btn_ok_sr {
        box-shadow: 0 0.21rem 0.24rem 0 rgba(162, 194, 212, 0.19);
        color: #fff;
        background: #2a69e2 //url(../../../../../assets/parking/d_new/btn_bg.png) 0 0/100% 100% no-repeat;;
    }

    .btn_cancel {
        width: 3.93rem;
        height: 1.05rem;
        // box-shadow: 0px 21px 24px rgba(162, 194, 212, 0.19);
        text-align: center;
        font-size: 0.36rem;
        border-radius: 0.16rem;
        font-weight: 400;
        letter-spacing: 0.04rem;
    }

    .btn_cancel_d {
        background: #383d54;
        color: #ffffff;
    }

    .btn_cancel_sr {
        // filter: drop-shadow(0px .21rem .24rem rgba(162, 194, 212, 0.19));
        background: #cbd4e2; //url(../../../../../assets/parking/d_new/btn_bg_white.png) 0 0/100% 100% no-repeat;
        color: #828793;
    }
}

@keyframes boxShow {
    from {
        top: -20%;
        opacity: 0;
    }
    to {
        top: 20%;
        opacity: 1;
        transform: translateX(-50%);
    }
}
@keyframes boxShow_center {
    from {
        top: -20%;
        opacity: 0;
    }
    to {
        top: 50%;
        opacity: 1;
    }
}
@keyframes boxHidden {
    0% {
        opacity: 1;
        top: 20%;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 0;
        top: -20%;
    }
}
@keyframes boxHidden_center {
    0% {
        opacity: 1;
        top: 50%;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0;
        top: -20%;
    }
}
@keyframes maskHidden {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
