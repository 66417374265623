@c_background: #f0f4fa;

.modalItemClass {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 5.06rem;
    height: 5.29rem;
    backdrop-filter: blur(0.8rem);
    border-radius: 0.29rem;
    z-index: 99;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &.imgText {
        background: rgba(113, 133, 170, 0.22);
        box-shadow: 0 0.03rem 0.56rem rgba(0, 0, 0, 0.4);
        overflow: hidden;

        img {
            width: 5.06rem;
            height: 3.92rem;
        }

        p {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 0.38rem;
            line-height: 0.31rem;
            color: #ffffff;
            margin: auto;
        }
    }

    &.imgImgsubText {
        width: 6.53rem;
        height: 6.53rem;
        background: rgba(113, 133, 170, 0.22);
        box-shadow: 0 0.03rem 0.56rem rgba(0, 0, 0, 0.4);
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            /* margin: .42rem 0 .14rem;
             width: 1.71rem;
             height: 1.20rem;*/
        }

        .imgSub {
            width: 3.04rem;
            height: 3.04rem;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0.91rem;
        }

        p {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 0.34rem;
            line-height: 0.31rem;
            color: #ffffff;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0.61rem;
        }
    }

    &.imgColorText {
        background: #2e3247;
        border-radius: 0.21rem;
        overflow: hidden;

        img {
            width: 5.06rem;
            height: 3.92rem;
        }

        p {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 0.38rem;
            line-height: 0.31rem;
            color: #ffffff;
            margin: auto;
        }
    }

    &.imgAmapWidth {
        background: rgba(113, 133, 170, 0.22);
        box-shadow: 0 0.03rem 0.56rem rgba(0, 0, 0, 0.4);
        overflow: hidden;

        img {
            width: 5.06rem;
            height: 3.6rem;
        }

        p {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 0.38rem;
            line-height: 0.31rem;
            color: #ffffff;
            margin: auto;
        }
    }

    &.imgNRPWidth {
        background: rgba(113, 133, 170, 0.22);
        box-shadow: 0 0.03rem 0.56rem rgba(0, 0, 0, 0.4);
        overflow: hidden;

        img {
            margin: 0 auto;
            width: 4.4rem;
            height: 4rem;
        }

        p {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 0.38rem;
            line-height: 0.31rem;
            color: #ffffff;
            margin: auto;
        }
    }

    &.c_imgText {
        background: @c_background;
        box-shadow: 0 0.03rem 0.56rem rgba(0, 0, 0, 0.4);
        overflow: hidden;

        img {
            width: 5.06rem;
            height: 3.92rem;
        }

        p {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 0.38rem;
            line-height: 0.31rem;
            color: #696f7b;
            margin: auto;
        }
    }

    &.c_imgImgsubText {
        width: 6.53rem;
        height: 6.53rem;
        background: @c_background;
        box-shadow: 0 0.03rem 0.56rem rgba(0, 0, 0, 0.4);
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            /* margin: .42rem 0 .14rem;
             width: 1.71rem;
             height: 1.20rem;*/
        }

        .imgSub {
            width: 3.04rem;
            height: 3.04rem;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0.91rem;
        }

        p {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 0.34rem;
            line-height: 0.31rem;
            color: #696f7b;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0.61rem;
        }
    }

    &.c_imgColorText {
        background: @c_background;
        box-shadow: 0 0.03rem 0.56rem rgba(0, 0, 0, 0.4);
        border-radius: 0.21rem;
        overflow: hidden;

        img {
            width: 5.06rem;
            height: 3.92rem;
        }

        p {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 0.38rem;
            line-height: 0.31rem;
            color: #696f7b;
            margin: auto;
        }
    }

    &.c_imgAmapWidth {
        background: @c_background;
        box-shadow: 0 0.03rem 0.56rem rgba(0, 0, 0, 0.4);
        overflow: hidden;

        img {
            width: 5.06rem;
            height: 3.6rem;
        }

        p {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 0.38rem;
            line-height: 0.31rem;
            color: #696f7b;
            margin: auto;
        }
    }

    &.c_imgNRPWidth {
        background: @c_background;
        box-shadow: 0 0.03rem 0.56rem rgba(0, 0, 0, 0.4);
        overflow: hidden;

        img {
            margin: 0 auto;
            width: 4.4rem;
            height: 4rem;
        }

        p {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 0.38rem;
            line-height: 0.31rem;
            color: #696f7b;
            margin: auto;
        }
    }
}
