.p-tool-area {
    position: absolute;
}

.p-tool-area.d {
    top: 3.8rem;
    left: 0.42rem;
}

.p-tool-area.sr {
    top: 0.42rem;
    right: 1.3rem;
    // background: rgba(245, 249, 255, 0.8);
    // box-shadow: 0px 0.178rem 0.203rem #cdd5e2;
    backdrop-filter: blur(0.144rem);
    /* Note: backdrop-filter has minimal browser support */
    // border-radius: 0.135rem;
}

.p-tool-area > ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.p-tool-area > ul li {
    display: flex;
    width: 1.32rem;
    height: 1.32rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.16rem;
    img {
        width: 0.6rem;
        height: 0.6rem;
    }
    span {
        padding-top: 0.07rem;
        font-size: 0.25rem;
        font-weight: 400;
        color: #606a7e;
    }
}

.p-tool-area.d > ul li {
    width: 1rem;
    height: 1rem;
    background: #24283e;
    img {
        width: 0.4rem;
        height: 0.4rem;
    }
    span {
        color: #fff;
        padding-top: 0.1rem;
        font-size: 0.2rem;
    }
}

.p-tool-area.d > ul li.active {
    opacity: 0.6;
}

.p-tool-area.sr > ul li {
    width: 1.32rem;
    height: 1.32rem;
    box-shadow: 0 0.21rem 0.24rem 0 #c3cddc;
}

.p-tool-area.sr > ul li.active {
    opacity: 0.3;
}
