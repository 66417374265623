@import '~@/components/slider/smartDrivingSetting/drivingAssistSetting/broadcastMode/navigationTips/index.less';
.navigation_tips_card_wrap.hmi-mode .select_box_item {
  width: 2.5rem;
}

.navigation_tips_card_wrap.hmi-mode.parking {
  background: transparent;
  margin-left: 1.61rem;
  width: auto;
  height: auto;
  padding: 0;
  &.sr {
    .title_wrap {
      color: #696f7b;
    }
  }
  &.d {
    .title_wrap {
      color: #fff;
    }
  }
  .title_wrap {
    padding-top: 0;
    font-size: .3rem;
    font-weight: 400;
  }
  .sensitivity_wrap {
    margin-top: .4rem;
    width: 4.18rem;
    height: .8rem;
    background: #97A4BE;
    border-radius: 1.09rem;
  }
  .select_box {
    width: 100%;
    height: 100%;
    display: flex;
    .select_box_item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: .24rem;
      border-radius: 1.09rem;
      color: #fff;
      &.active {
        background: #fff;
        box-shadow: 0px 4px 4px rgba(71, 82, 101, 0.1);
        color: #696F7B;
      }
    }
  }
}