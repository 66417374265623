// 横屏
.horizontal {
  .widget-class {
    position: absolute;
    //top: 3.75rem;
    top: 1.4rem;
    z-index: 999;
    //min-width: 3.5rem !important;
    max-width: 6.4rem !important;
    border-radius: .16rem;
    margin-bottom: .2rem;

    .widget-class-wrap {
      display: flex;
      background: #272B3E;
      border-radius: .16rem;
      //padding: .2rem;
      align-items: center;
      padding: 0 .3rem;
      height: 1.1rem;
      box-sizing: border-box;

      .custom_toast-title {
        font-size: .30rem;
        font-weight: 500;
        white-space: nowrap;
        //padding-bottom: .1rem;
      }

      .custom_toast-subtitle {
        font-size: .26rem;
        font-weight: 400;
        color: rgba(255,255,255,0.6);
        padding-top: .1rem;
      }

      img {
        width: .54rem;
        height: .54rem;
      }

      .text-box {
        font-size: .30rem;
        font-weight: 500;
        color: #fff;
        text-align: center;
        flex: 1;
      }
    }

    .icon-show {
      .text-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: .3rem;
        text-align: left;
        white-space: pre-wrap;
      }
    }
  }
}

// 竖屏
.vertical {
  .widget-class {
    position: absolute;
    left: 0.54rem;
    top: 2.5rem;
    z-index: 999;
    //min-width: 3.5rem !important;
    max-width: 5.4rem !important;
    border-radius: .16rem;

    .widget-class-wrap {
      display: flex;
      background: rgba(56, 61, 84, 1);
      border-radius: .16rem;
      //padding: .2rem;
      align-items: center;
      padding: 0 .3rem;
      height: 1.1rem;
      box-sizing: border-box;

      .custom_toast-title {
        font-size: .30rem;
        font-weight: 500;
        //padding-bottom: .1rem;
      }

      .custom_toast-subtitle {
        font-size: .26rem;
        padding-top: .1rem;
      }

      img {
        width: .54rem;
        height: .54rem;
      }

      .text-box {
        font-size: .26rem;
        font-weight: 500;
        color: #fff;
        text-align: center;
        flex: 1;
      }
    }

    .icon-show {
      .text-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: .3rem;
        text-align: left;
        white-space: pre-wrap;
      }
    }
  }
}

