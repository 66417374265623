.exception-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    .exception-title{
      font-size: .24rem;
      color: #8991A0;
      margin-top: .22rem;

    }
  }