.test-modal-mask {
    width: calc(100% - 1.35rem);
    height: 100%;
    position: fixed;
    top: 0;
    right: 0 !important;
    background: rgba(00, 00, 00, 0.8);
    padding: 1.62rem 0.71rem 1.26rem 0.71rem;
    z-index:1002;
}
.test-modal-box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 0.19rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.modal-com {
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 0 0.04rem 0.44rem rgba(0, 0, 0, 0.1);
    border-radius: 0.19rem;
    padding: 0.5rem 0.29rem 0.29rem 0.29rem;
    display: flex;
    flex-direction: column;
    z-index: 1000;

    .modal-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        .close {
            font-size: .23rem;
            width: .2rem;
            height: .2rem;
            color: #666E7E;
        }
        .title-text {
            margin-left: 0.25rem;
            font-weight: 500;
            font-size: 0.32rem;
            line-height: 0.25rem;
            color: #454A4F;
        }
    }

    .modal-data {
        width: 100%;
        height: 100%;
        margin-top: 0.3rem;
        background: #F5F5F5;
        border-radius: 0.19rem;
        padding: 0 0.36rem;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        .data-header {
            display: flex;
            justify-content: space-between;
            padding-top: 0.4rem;

            .search {
                margin-top: 0.2rem;
                .search-button {
                    padding: 0.17rem 0.62rem;
                    background: #2A69E2;
                    box-shadow: 0 0.04rem 0.10rem rgba(0, 0, 0, 0.1);
                    border-radius: 0.12rem;
                    font-weight: 500;
                    font-size: 0.31rem;
                    line-height: 0.43rem;
                    color: #FFFFFF;
                }
                .question {
                    margin-top: 0.3rem;
                    font-weight: 500;
                    font-size: 0.39rem;
                    color: #454A4F;
                }
            }

            .title-message {
                border: 0.01rem solid #727A82;
                border-radius: 0.1rem;
                padding: 0.32rem 0.4rem;
                min-width: 5.92rem;
                .message-title {
                    font-weight: 500;
                    font-size: 0.3rem;
                    line-height: 0.25rem;
                    color: #454A4F;
                    margin-bottom: 0.37rem;
                }
                .messageCommon {
                    font-weight: 400;
                    font-size: 0.24rem;
                    line-height: 0.25rem;
                    color: #454A4F;
                    margin-top: 0.13rem;
                    span {
                        font-weight: 500;
                    }
                }
                .box-id {
                    margin-top: 0.27rem !important;
                }
            }
        }

        .data-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 0.3rem;

            .data-item {
                display: flex;
                flex-direction: column;

                .data-item-title {
                    margin-top: 0.44rem;
                    font-weight: bold;
                    font-size: 0.30rem;
                    display: flex;
                    align-items: center;
                    color: #2A69E2;
                }

                .data-item-list {
                    margin-top: 0.16rem;
                    ul,li {
                        list-style: disc;
                    }
                    .data-item-ul {
                        .data-item-normal {
                            margin-left: 0.4rem;
                            font-size: 0.24rem;
                            line-height: 0.52rem;
                            color: #454A4F;
                        }
                    }
                }

                .waring {
                    color: #FF7400 !important;
                }
            }
        }

        .data-error {
            margin-top: 0.5rem;
            font-size: 0.4rem;
            font-weight: 600;
            color: #FF7400;
        }
    }
}
.sideBar.black, .Layout-box.black{
    .test-modal-mask{
        // background: rgba(9, 11, 28, 0.6);
        background: rgba(00, 00, 00, 0.8);
    }
    .modal-com{
        background: #383D54;
        box-shadow: 0 .04rem .44rem rgba(0, 0, 0, 0.1);
        .close{
            color: #B0BCF8;
        }
        .modal-title{
            .title-text{
                color: #B0BCF8;
            }
        }
        .modal-data{
            background: #2E3247;
            .question{
                color: #B0BCF8;
            }
            .data-content {
                .data-item {
                    .data-item-title {
                        color: #B0BCF8;
                    }
                    .data-item-list {
                        .data-item-ul {
                            .data-item-normal {
                                color: #EAEDFF;
                            }
                        }
                    }
                }
            }
        }

        .data-header {
            .title-message {
                .message-title {
                    color: #B0BCF8;
                }
                .messageCommon {
                    color: #B0BCF8;
                }
            }
        }
    }

}
.server_trigger {
    text-align: right;
    margin: 0.24rem 0.16rem 0 0;

    .button_wrap {
        font-size: 0.26rem
    }
}
