.slider{
  width: 1.35rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: .43rem 0;
  //padding: .2rem 0 .53rem 0;
  &-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index:1000;
  }
  &-item:not(:last-child){
    margin-bottom: .45rem;
  }
  .iconfont-slider{
    font-size: .8rem;
    display: flex;
    justify-content: center;
    color: #fff;
  }
  .hands-off{
    margin-top: .1rem;
  }
  &-item.active{
    span{
      background: #0068EB;
      border-radius: .16rem;
    }
  }
  &-item.line.active{
    span{
      background:none;
      color: #0068EB;
    }
  }
  &-top{
    color: #FFFFFF;
    text-align: center;
    .position-num{
      font-weight: 300;
      font-size: .51rem;
    }
    .position-title{
      font-weight: 500;
      font-size: .18rem;
    }
  }
  &-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1000;

    ul {
      padding: .2rem .15rem;
    }

    &-content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      // width: auto;
      // margin: 0 auto;
      color: #9D9D9D;
    }
  }
}
