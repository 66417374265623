.switch-module-container {
    //.switch-toast {
    //    position: fixed;
    //    top: .4rem;
    //    left: 2.09rem;
    //    width: 18.8rem;
    //    height: .96rem;
    //    line-height: 0.96rem;
    //    background: #F3F3F3;
    //    border-radius: .19rem;
    //    .switch-toast-text {
    //        font-weight: 500;
    //        font-size: .32rem;
    //        text-align: center;
    //        color: #495160;
    //    }
    //}
    .container-top {
        display: flex;
        justify-content: space-between;
        .main-swtich {
            width: 6.04rem;
            height: 3.06rem;
            // background-image: url('../../../../../assets/slider/cp_white.jpg');
            background-repeat: no-repeat;
            background-size: 100%;
            border-radius: .42rem;
            padding: .46rem;
        }
    }
    .container-bottom {
        display: flex;
        justify-content: space-between;
    }
    .customise-title, .switch-type {
        font-weight: 600;
        font-size: .32rem;
        line-height: .45rem;
        color: #495160;
    }
    .switch-title {
        font-weight: 600;
        font-size: .26rem;
        line-height: .45rem;
        color: rgba(73, 81, 96, 0.6);
    }
    .customist-tip {
        font-weight: 500;
        font-size: 0.25rem;
        line-height: 0.25rem;
        padding-top: 0.8rem;
        text-align: center;
        color: rgba(73, 81, 96, 0.6);
    }
    .customise-content {
        color: #495160;
        background: rgba(255, 255, 255, 0.45);
        margin-top: .3rem;
        padding: .36rem .46rem;
        border-radius: .42rem;
        // min-height: 7rem;
        min-height: 3rem;
        width: 6.04rem;
    }
    .is-filter-version {
        min-height: 7rem;
    }
    .is_disabled {
        opacity: .4;
    }
    .disabled {
        opacity: .6;
    }
}

.switch-module-container.black, .Layout-box.black {
    //.switch-toast {
    //    background: #383D54;
    //    .switch-toast-text {
    //        color: #FFFFFF;
    //    }
    //}
    .customise-title, .switch-title, .switch-type {
        color: #FFFFFF;
    }
    .customist-tip {
        color: #FFFFFF;
    }
    .customise-content {
        color: #FFFFFF;
        background: #383D54;
    }
}
