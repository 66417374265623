.setting-modal-mask{
    width: calc(100% - 1.35rem);
    height: 100%;
    position: fixed;
    top: 0;
    right: 0 !important;
    background: rgba(00, 00, 00, 0.8);
    padding: 1.62rem 0.71rem 1.26rem 0.71rem;
    z-index:1002;
    .setting-modal-box {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border-radius: 0.19rem;
        overflow: hidden;
        background-color: #F3F3F3;
        display: flex;
        justify-content: center;
        .modal-box-left {
            width: 20%;
            height: 100%;

            .close-button-group {
                display: flex;
                align-items: center;
                color: #495160;
                .close {
                    padding: .61rem .25rem 0 .6rem;
                    font-size: .23rem;
                }
                .modal-title{
                    padding: .61rem 0 0 0;
                    font-size: .32rem;
                    font-weight: 500;
                }
            }

            .setting-modal-tabs {
                padding-top: 0.76rem;
                .tab-item {
                    padding: .57rem 0 .57rem 1.08rem;
                    font-weight: 500;
                    font-size: .29rem;
                    // display: flex;
                    // align-items: center;
                    color: #495160;
                }
                .active {
                    color: #2A69E2;
                }
            }
        }
        .modal-box-right {
            width: 80%;
            height: 100%;
            background-color: #E6E6E6;
            overflow-y: auto;

            .common-switch {
                margin-top: .54rem;
                --width: .95rem;
                --height: .57rem;
                .adm-switch-checkbox{
                    border-radius: .55rem;
                }
                .adm-switch-checkbox:before {
                    background: #C9C9C9;
                }
            }
            .select-group-switch {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-top: .3rem;
                .custom-switch{
                    --width: .88rem;
                    --height: .5rem;
                    .adm-switch-checkbox{
                        border-radius: .55rem;
                    }
                    .adm-switch-checkbox:before {
                        background: #C9C9C9;
                    }
                }
                .switch-name{
                    font-weight: 500;
                    font-size: .28rem;
                    color: #495160;
                    padding-left: .2rem;
                }
            }
            .select-group-item {
                margin-top: .5rem;
                .select-group-item-name {
                    font-weight: 500;
                    font-size: .25rem;
                    line-height: .25rem;
                    padding-bottom: .24rem;
                }
                .auto-lanechange-tip {
                    color: #787E88;
                    font-size: .2rem;
                    line-height: .25rem;
                    padding-top: .2rem;
                }
                .select-box{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background: #E6E6E6;
                    border-radius: .8rem;
                    .select-box-item{
                        text-align: center;
                        flex: 1;
                        height: .8rem;
                        line-height: .8rem;
                        font-size: .25rem;
                        font-weight: 500;
                        white-space: nowrap;
                        color: rgba(73, 81, 96, 0.7);
                    }
                    .select-box-item.active{
                        background: #F9FAFD;
                        box-shadow: 0 .04rem .04rem rgba(71, 82, 101, 0.1);
                        border-radius: .8rem;
                        color: #495160;
                    }
                }
            }
        }
    }
}

.setting-modal-mask.black, .Layout-box.black {
    .setting-modal-box {
        background: #383D54;
        .close-button-group {
            color: #FFFFFF;
        }
        .setting-modal-tabs {
            .tab-item {
                color: rgba(255, 255, 255, 0.4);
            }
            .active {
                color: #FFFFFF;
            }
        }
        .modal-box-right {
            background: #2E3247;
            .adm-switch-checked.custom-switch, .adm-switch-checked.common-switch{
                .adm-switch-handle {
                    background: #FFFFFF;
                }
                .adm-switch-checkbox,.adm-switch-checkbox:before {
                    background: #1677ff;
                }
            }
            .custom-switch, .common-switch {
                .adm-switch-handle {
                    background: #585D75;
                }
                .adm-switch-checkbox,.adm-switch-checkbox:before {
                    background: #2E3247;
                }
            }
            .select-group-switch {
                .switch-name{
                    color: #FFFFFF;
                }
            }
            .select-group-item {
                .select-box{
                    background: #2E3247;
                    .select-box-item{
                        color: rgba(255, 255, 255, 0.7);
                    }
                    .select-box-item.active{
                        background: #585D75;
                        box-shadow: 0px 4px 4px rgba(71, 82, 101, 0.1);
                        border-radius: .8rem;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
}
