.task-detail-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  padding: 0.28rem;
  display: flex;
  flex-direction: column;
  .title {
    font-size: 0.28rem;
    font-weight: 600;
    margin-bottom: 0.24rem;
  }
  .task-info {
    // flex: 1;
    height: 100%;
    background-color: rgba(248, 249, 251, 1);
    border-radius: 0.1rem;
    padding: 0.36rem;
    position: relative;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    .back-content{
      font-size: .24rem;
      position: absolute;
      right: 0;
      right: 0.3rem;
      color: #8991A0;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
    }
    .task-title {
      font-weight: 500;
      font-size: 0.3rem;
      color: #495160;
      margin-bottom: 0.3rem;
    }
    .task-info-list {
      // flex: 1;
      background: #F3F4F7;
      padding: 0.2rem;
      max-height: 6.5rem;
      overflow-y: scroll;
    }
    .task-info-item {
      font-size: 0.28rem;
      color: #495160;
      line-height: 1.3;
      display: flex;
      align-items: center;
      white-space: normal;
      word-break: break-all;
      &:not(:first-child) {
        padding-top: 0.20rem;
      }
    }
    .btn-box {
      width: 100%;
      margin-top: 0.3rem;
      .adm-button {
        font-size: 0.26rem;
        width: 3rem;
        padding: 0.2rem 0;
      }
      position: absolute;
      bottom: .35rem;
    }
    .task-detail-loading{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      background: rgba(0,0,0,.6);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .status_label{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 0.22rem;
    margin-left: 0.18rem;
    padding: 0.1rem;
    white-space: nowrap;
  }
  .status_label.not_download, .status_label.downloading, .status_label.downloaded, .status_label.deploying{
    color: #9A9EAA;
    border: 1px solid #9A9EAA;
  }
  .status_label.running{
    color: #1CADFF;
    border: 1px solid #1CADFF;
  }
  .adm-modal-body .adm-modal-content{
    padding: 0;
  }
  .modal-content{
    width: 6.4rem;
    min-height: 2.3rem;
    .title-content{
      display: block;
      margin-left: .25rem;
      font-size: .28rem;
      font: bold;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.02em;
      color: #495160;
      margin-bottom: .39rem;
      margin-top: .22rem;
    }
    .cancel{
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: .26rem;
      color: #8991A0;
    }
    .comfirm{
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: .26rem;
      color: #FFFFFF;
    }
  }
  .tasks-contains{
    max-height: 2.4rem;
    margin-top: .2rem;
    overflow: auto;
  }
  .task-content{
    height: .48rem;
    background: #DEE9FF;
    line-height: .46rem;
    .t-title{
      margin-left: .26rem;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: .22rem;
      color: #495160;
    }
    .t-miles{
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: .19rem;
      color: #495160;
      span{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: .23rem;
        color: #495160;

      }
    }
  }
  .table-content{
    background: #F4F8FF;
    line-height: .44rem;
    border-bottom: 1px solid #DEE9FF ;
    .t-route{
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: .20rem;
      color: #495160;
      display: inline-block;
      text-align: left;
      margin-left: .26rem;
    }
    .route-miles{
      margin-left: .15rem;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: .22rem;
      color: #495160;
      display: inline-block;
      margin-left: .1rem;      
    }
  }
 
}

